import React from 'react';
import HomepageLive from "../../components/HomepageLive/HomepageLive";
import HomepageNotLive from "../../components/HomepageNotLive/HomepageNotLive";

function Homepage() { 
  return (
    <HomepageNotLive/>
  );
}

export default Homepage;